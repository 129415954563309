:root {
  --pbpadding: 3em;
  --pbdoublepadding: 6em;
  --pbtriplepadding: 9em;
}

:root {
  --base-font-size: 17px;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  color: #000000;
  font-size: 17px;
  font-family: "Mont-light", Arial;
  font-variation-settings: "wght" 400;
  background-color: white;
  position: relative;
}

p {
  font-size: var(--base-font-size);
  line-height: 1.65em;
  margin-bottom: 1.25em;
}

strong,
b {
  font-family: "Mont-bold";
  font-variation-settings: "wght" 600;
}

img {
  height: auto;
}

h2 + p,
h3 + p,
h4 + p,
h5 + p,
h5 + ul {
  margin-top: 1rem;
}

p + .keylink {
  margin-top: 1em;
}

p + ul {
  margin-bottom: 2em;
}

a:not(.nav-link, .skiplink, .reset-link), .link {
  color: #000;
  text-decoration: none;
}
a:not(.nav-link, .skiplink, .reset-link):hover, .link:hover {
  text-decoration: underline;
}

article.text-side a, article a {
  text-underline-offset: 0.3em;
}

.reset-link {
  text-decoration: none !important;
  color: black !important;
}
.reset-link:hover {
  text-decoration: underline !important;
  text-underline-offset: 3px;
}

:is(.backto.backto) {
  text-decoration: none;
  opacity: 0.6;
  margin-top: 2rem;
  display: inline-block;
}
:is(.backto.backto) img {
  width: 27px;
  margin-right: 5px;
}
:is(.backto.backto):hover {
  text-decoration: underline;
}

.mysuperflexteststyle {
  background-color: red;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

*:focus:not(:focus-visible) {
  outline: none !important;
}

div.objectfit {
  height: 100%;
}
div.objectfit img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

svg.icon {
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  fill: currentColor;
  position: relative;
  top: 3px;
  margin: -2px 5px 0 0;
}

.skiplink {
  position: absolute;
  left: -9999em;
}
.skiplink:focus {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  font-size: 2rem;
  z-index: 1000;
  background: #000000;
  padding: 1rem 1.8rem;
  border: 4px solid white;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.withflex {
  display: flex;
}

.centred {
  text-align: center;
}

.align-right {
  text-align: right;
}

.gorel {
  position: relative;
}

.noflow {
  overflow: hidden;
}

.rounded-box {
  border-radius: 18px;
  overflow: hidden;
}

.bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-blue {
  background-color: #db0132;
  color: #FFF;
}

.bg-grey {
  background-color: #ededed;
}

.bg-greylt {
  background-color: #F5F5F5;
}

.bg-sky {
  background-color: #ededed;
}

.gowhite {
  color: #FFF;
}

.goblue {
  color: #db0132;
}

.bordered-blue {
  border: 0.5px solid #db0132;
}

.with-margin-top {
  margin-top: var(--pbpadding);
}

.with-margin-bottom {
  margin-bottom: var(--pbpadding);
}

.nomargin-top {
  margin-top: 0 !important;
}

.nomargin-bottom {
  margin-bottom: 0 !important;
}

.nopadding-top {
  padding-top: 0 !important;
}

.nopadding-bottom {
  padding-bottom: 0 !important;
}

.noflow {
  overflow: hidden;
}

.smaller-text {
  font-size: 13px;
}
.smaller-text h2 {
  font-size: 26px;
}
.smaller-text p {
  font-size: 13px;
}

@media (max-width: 768px) {
  p + .keylink {
    margin-top: 0.25em;
  }
}
.pb-copy-panel .copy-wrapper h3 + p, .pb-copy-panel .copy-wrapper .subheading {
  margin-top: 2.25rem;
}
.pb-copy-panel .copy-wrapper .pre-heading {
  display: block;
  margin-bottom: 0.75em;
}
.pb-copy-panel .copy-wrapper .leftcol img, .pb-copy-panel .copy-wrapper .rightcol img {
  margin-top: 0;
}
.pb-copy-panel .copy-wrapper .leftcol img.not-rounded, .pb-copy-panel .copy-wrapper .rightcol img.not-rounded {
  border-radius: 0;
}
.pb-copy-panel .copy-wrapper .nexbtn {
  margin-top: 1.8em;
}
.pb-copy-panel .copy-wrapper .leftcol.copycol h3.key-heading {
  max-width: 575px;
  white-space: normal;
}
@media (max-width: 768px) {
  .pb-copy-panel .copy-wrapper .leftcol.copycol h3.key-heading {
    margin-left: auto;
    margin-right: auto;
  }
}
.pb-copy-panel__onecol-fullcol .copy-wrapper .rightcol {
  max-width: 100%;
}
.pb-copy-panel__onecol-right {
  background-image: url("../../assets/images/circle_left.png");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 300px;
}
.pb-copy-panel__onecol-right .copy-wrapper .rightcol {
  max-width: 740px;
  float: right;
}
.pb-copy-panel__twocol-os-right .copy-wrapper {
  display: flex;
  justify-content: flex-end;
}
.pb-copy-panel__twocol-os-right .copy-wrapper .leftcol {
  flex-basis: 41%;
  margin-right: 4.7%;
}
.pb-copy-panel__twocol-os-right .copy-wrapper .rightcol {
  flex-basis: 37.8%;
}
.pb-copy-panel__twocol-fifty {
  /*&.bg-grey {
      & {
          &.bg-circle-top-right {
              .copy-wrapper {
                  padding-top: var(--pbpadding);
                  padding-bottom: var(--pbpadding);
              }
          }
      }
  }*/
}
.pb-copy-panel__twocol-fifty .copy-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}
.pb-copy-panel__twocol-fifty .copy-wrapper .leftcol, .pb-copy-panel__twocol-fifty .copy-wrapper .rightcol {
  width: 50%;
  flex-basis: 50%;
}
.pb-copy-panel__twocol-fifty .copy-wrapper .leftcol .image-wrap {
  float: left;
}
.pb-copy-panel__twocol-fifty .copy-wrapper .leftcol .image-wrap img {
  display: block;
}
@media (max-width: 768px) {
  .pb-copy-panel__twocol-fifty .copy-wrapper .leftcol .image-wrap {
    width: 100%;
    float: none;
  }
  .pb-copy-panel__twocol-fifty .copy-wrapper .leftcol .image-wrap img {
    margin: 0 auto;
  }
}
.pb-copy-panel__twocol-fifty .copy-wrapper .rightcol .image-wrap {
  float: right;
}
.pb-copy-panel__twocol-fifty .copy-wrapper .rightcol .image-wrap img {
  display: block;
}
@media (max-width: 768px) {
  .pb-copy-panel__twocol-fifty .copy-wrapper .rightcol .image-wrap {
    width: 100%;
    float: none;
  }
  .pb-copy-panel__twocol-fifty .copy-wrapper .rightcol .image-wrap img {
    margin: 0 auto;
  }
}
.pb-copy-panel__twocol-fifty .type-image img {
  width: 100%;
  max-width: 520px;
}
.pb-copy-panel__twocol-fifty.extra-gap .copy-wrapper {
  gap: 4em;
}
.pb-copy-panel__twocol-fifty.align-top .copy-wrapper {
  align-items: flex-start;
}
@media (max-width: 1080px) {
  .pb-copy-panel__twocol-fifty .copy-wrapper {
    grid-gap: 2em;
  }
}
.pb-copy-panel__twocol-sixty-forty .copy-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 1em;
  justify-content: space-between;
}
.pb-copy-panel__twocol-sixty-forty .copy-wrapper .leftcol {
  flex-basis: 63.25%;
}
.pb-copy-panel__twocol-sixty-forty .copy-wrapper .rightcol {
  flex-basis: 32.75%;
}
.pb-copy-panel__twocol-sixty-forty .copy-wrapper .leftcol.type-tabs {
  border-top: 1px solid #db0132;
  padding-top: 2em;
}
@media (max-width: 1080px) {
  .pb-copy-panel__twocol-sixty-forty .copy-wrapper .leftcol, .pb-copy-panel__twocol-sixty-forty .copy-wrapper .rightcol {
    flex-basis: 50%;
  }
}
@media (max-width: 768px) {
  .pb-copy-panel__twocol-sixty-forty .copy-wrapper {
    text-align: center;
    flex-direction: column;
    gap: 2;
  }
  .pb-copy-panel__twocol-sixty-forty .copy-wrapper .leftcol, .pb-copy-panel__twocol-sixty-forty .copy-wrapper .rightcol {
    flex-basis: 100%;
  }
}
.pb-copy-panel.flex-top .copy-wrapper {
  align-items: flex-start;
}
.pb-copy-panel .expanding-panels {
  margin-top: 2em;
}
.pb-copy-panel .expanding-panels .panel-item {
  margin-bottom: 21px;
}
.pb-copy-panel .expanding-panels .panel-item .panel-heading {
  font-family: "Mont-book";
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 25px 0 0;
  justify-content: space-between;
}
.pb-copy-panel .expanding-panels .panel-item .panel-heading h4 {
  font-size: 20px;
  flex-basis: calc(100% - 35px);
}
.pb-copy-panel .expanding-panels .panel-item .panel-heading h4 strong {
  font-family: "Mont-semi";
}
.pb-copy-panel .expanding-panels .panel-item .panel-heading .trigger-icon {
  background-image: url("../../assets/images/icon_arrow_expander.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: none;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  transition: all 350ms ease-in-out;
  display: block;
  flex-basis: 35px;
}
.pb-copy-panel .expanding-panels .panel-item .panel-heading.active .trigger-icon {
  transform: rotate(180deg);
}
.pb-copy-panel .expanding-panels .panel-item .panel-copy {
  padding-top: 1em;
  display: none;
}
.pb-copy-panel .expanding-panels .panel-item .panel-copy p, .pb-copy-panel .expanding-panels .panel-item .panel-copy ol, .pb-copy-panel .expanding-panels .panel-item .panel-copy ul {
  margin-bottom: 2em;
}
.pb-copy-panel .expanding-panels .copycol.post-copy ul li::before {
  transform: translateY(0);
}
.pb-copy-panel .expanders-subtext {
  border-top: 1.25px solid #db0132;
  padding-top: 1.5em;
}
.pb-copy-panel p + .stats-grid {
  margin-top: 5em;
}
@media (max-width: 1080px) {
  .pb-copy-panel p + .stats-grid {
    margin-top: 2em;
  }
}
.pb-copy-panel .stats-grid {
  font-family: Mont-Book;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 1em;
  align-items: center;
}
.pb-copy-panel .stats-grid .stat-item {
  color: #db0132;
  border: 1px solid #db0132;
  padding: 7% 3%;
  text-align: center;
}
.pb-copy-panel .stats-grid .stat-item .inner .thestat-row .thestat, .pb-copy-panel .stats-grid .stat-item .inner .thestat-row .statsub {
  font-size: 50px;
}
.pb-copy-panel .stats-grid .stat-item .inner .thestat-row .statdesc {
  margin-top: -7px;
  display: block;
}
@media (max-width: 1080px) {
  .pb-copy-panel .stats-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (max-width: 768px) {
  .pb-copy-panel .stats-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 0.5em;
  }
}
@media (max-width: 480px) {
  .pb-copy-panel .stats-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 0.5em;
  }
  .pb-copy-panel .stats-grid .stat-item {
    padding: 1em;
  }
}
@media (max-width: 1280px) {
  .pb-copy-panel__twocol-os-right .copy-wrapper .leftcol {
    flex-basis: 50%;
    margin-right: 4.7%;
  }
  .pb-copy-panel__twocol-os-right .copy-wrapper .rightcol {
    flex-basis: 45.3%;
  }
}
@media (max-width: 920px) {
  .pb-copy-panel.break-early .copy-wrapper .leftcol {
    margin-bottom: 2em;
  }
  .pb-copy-panel.break-early .copy-wrapper .leftcol.no-margin {
    margin-bottom: 0;
  }
  .pb-copy-panel.break-early .copy-wrapper h3.key-heading {
    display: inline-block;
  }
  .pb-copy-panel.break-early.pb-copy-panel__twocol-os-right .copy-wrapper {
    display: block;
  }
  .pb-copy-panel.break-early.pb-copy-panel__twocol-os-right .copy-wrapper .leftcol, .pb-copy-panel.break-early.pb-copy-panel__twocol-os-right .copy-wrapper .rightcol {
    width: 100%;
  }
  .pb-copy-panel.break-early.pb-copy-panel__twocol-os-right .copy-wrapper .leftcol {
    margin-bottom: 4em;
  }
  .pb-copy-panel.break-early.pb-copy-panel__twocol-fifty .copy-wrapper {
    display: block;
  }
  .pb-copy-panel.break-early.pb-copy-panel__twocol-fifty .copy-wrapper .leftcol, .pb-copy-panel.break-early.pb-copy-panel__twocol-fifty .copy-wrapper .rightcol {
    width: 100%;
    text-align: center;
  }
  .pb-copy-panel .expanding-panels {
    text-align: left;
  }
}
@media (max-width: 768px) {
  .pb-copy-panel .copy-wrapper .leftcol {
    margin-bottom: 2em;
  }
  .pb-copy-panel .copy-wrapper .leftcol.no-margin {
    margin-bottom: 0;
  }
  .pb-copy-panel__twocol-os-right .copy-wrapper {
    display: block;
  }
  .pb-copy-panel__twocol-os-right .copy-wrapper .leftcol, .pb-copy-panel__twocol-os-right .copy-wrapper .rightcol {
    width: 100%;
  }
  .pb-copy-panel__twocol-os-right .copy-wrapper .leftcol {
    margin-bottom: 4em;
    text-align: center;
  }
  .pb-copy-panel__twocol-fifty .copy-wrapper {
    display: block;
  }
  .pb-copy-panel__twocol-fifty .copy-wrapper .leftcol, .pb-copy-panel__twocol-fifty .copy-wrapper .rightcol {
    width: 100%;
    text-align: center;
  }
}