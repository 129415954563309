@import '../abstracts/variables.scss';
@import '../abstracts/mixins.scss';
@import '../sections/base.scss';

.pb-copy-panel {
    .copy-wrapper {
        h3 + p, .subheading {
            margin-top: 2.25rem;
        }
        .pre-heading {
            display: block;
            margin-bottom: 0.75em;
        }
        .leftcol, .rightcol {
            img {
                margin-top: 0;
                //border-radius: 18px;
                &.not-rounded {
                    border-radius: 0;
                }
            }
        }
        .nexbtn {
            margin-top: 1.8em;
        }
        .leftcol {
            &.copycol {
                h3 {
                    &.key-heading {
                        max-width: 575px;
                        white-space: normal;
                    }
                }
            }
        }
        @include bpr-media-breakpoint(down, md) {
            .leftcol {
                &.copycol {
                    h3 {
                        &.key-heading {
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                }
            }
        }
    }
    &__onecol-fullcol {
        .copy-wrapper {
            .rightcol {
                max-width: 100%;
            }
        }
    }
    &__onecol-right {
        background-image: url('../../assets/images/circle_left.png');
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 300px;
        .copy-wrapper {
            .rightcol {
                max-width: 740px;
                float: right;
            }
        }
    }
    &__twocol-os-right {
        .copy-wrapper {
            display: flex;
            justify-content: flex-end;
            .leftcol {
                flex-basis: 41%;
                margin-right: 4.7%;
            }
            .rightcol {
                flex-basis: 37.8%;
            }
        }
    }
    &__twocol-fifty {
        /*&.bg-grey {
            & {
                &.bg-circle-top-right {
                    .copy-wrapper {
                        padding-top: var(--pbpadding);
                        padding-bottom: var(--pbpadding);
                    }
                }
            }
        }*/
        .copy-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1em;
            .leftcol, .rightcol {
                width: 50%;
                flex-basis: 50%;
            }
            .leftcol {
                .image-wrap {
                    float: left;
                    img {
                        display: block;
                    }
                } 
                @include bpr-media-breakpoint(down, md) {
                    .image-wrap {
                        width: 100%;
                        float: none;
                        img {
                            margin: 0 auto;
                        }
                    } 
                }
            }
            .rightcol {
                .image-wrap {
                    float: right;
                    img {
                        display: block;
                    }
                } 
                @include bpr-media-breakpoint(down, md) {
                    .image-wrap {
                        width: 100%;
                        float: none;
                        img {
                            margin: 0 auto;
                        }
                    } 
                }
            }
        }
        .type-image {
            img {
                width: 100%;
                max-width: 520px;
            }
        }
        &.extra-gap {
            .copy-wrapper {
                gap: 4em;
            }
        }
        &.align-top {
            .copy-wrapper {
                align-items: flex-start;
            }
        }
        @include bpr-media-breakpoint(down, hd) {
            .copy-wrapper {
                grid-gap: 2em;
            }
        }
    }
    &__twocol-sixty-forty {
        .copy-wrapper {
            display: flex;
            justify-content: space-between;
            gap: 1em;
            justify-content: space-between;
            .leftcol {
                flex-basis: 63.25%;
            }
            .rightcol {
                flex-basis: 32.75%;
            }
            .leftcol {
                &.type-tabs {
                    border-top: 1px solid $atlasred;
                    padding-top: 2em;
                }
            }
        }
        @include bpr-media-breakpoint(down, hd) {
            .copy-wrapper {
                .leftcol, .rightcol {
                    flex-basis: 50%;
                }
            }
        }
        @include bpr-media-breakpoint(down, md) {
            .copy-wrapper {
                text-align: center;
                flex-direction: column;
                gap: 2;
                .leftcol, .rightcol {
                    flex-basis: 100%;
                }
            }
        }
    }
    &.flex-top {
        .copy-wrapper {
            align-items: flex-start;
        }
    }
    .expanding-panels {
        margin-top: 2em;
        .panel-item {
            margin-bottom: 21px;
            .panel-heading {
                font-family: 'Mont-book';
                width: 100%;
                display: flex;
                align-items: flex-start;
                padding: 25px 0 0;
                justify-content: space-between;
                h4 {
                    font-size: 20px;
                    flex-basis: calc(100% - 35px);
                    strong {
                        font-family: 'Mont-semi';
                    }
                }
                .trigger-icon {
                    background-image: url('../../assets/images/icon_arrow_expander.svg');
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    border: none;
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    transition: all 350ms ease-in-out;
                    display: block;
                    flex-basis: 35px;
                }
                &.active {
                    .trigger-icon {
                        transform: rotate(180deg);
                    }
                }
            }
            .panel-copy {
                padding-top: 1em;
                //font-size: 13px;
                display: none;
                p, ol, ul {
                    margin-bottom: 2em;
                }
                &.active {
                    //display: block;
                }
            }
        }
        .copycol.post-copy ul li::before {
            transform: translateY(0);
        }
    }
    .expanders-subtext {
        border-top: 1.25px solid $atlasred;
        padding-top: 1.5em;
    }
    p + .stats-grid {
        margin-top: 5em;
        @include bpr-media-breakpoint(down, hd) {
            margin-top: 2em;
        }
    }
    .stats-grid {
        font-family: Mont-Book;
        display: grid;
        grid-template-columns: repeat(3,minmax(0,1fr));
        grid-gap: 1em;
        align-items: center;
        .stat-item {
            color: $atlasred;
            border: 1px solid $atlasred;
            padding: 7% 3%;
            text-align: center;
            .inner {
                .thestat-row {
                    .thestat, .statsub {
                        font-size: 50px;
                    }
                    .statdesc {
                        margin-top: -7px;
                        display: block;
                    }
                }
            }
        }
        @include bpr-media-breakpoint(down, hd) {
            grid-template-columns: repeat(2,minmax(0,1fr));
        }
        @include bpr-media-breakpoint(down, md) {
            grid-template-columns: repeat(3,minmax(0,1fr));
            grid-gap: 0.5em;
        }
        @include bpr-media-breakpoint(down, sm) {
            grid-template-columns: repeat(1,minmax(0,1fr));
            grid-gap: 0.5em;
            .stat-item {
                padding: 1em;
            }
        }
    }
    @include bpr-media-breakpoint(down, xxl) {
        &__twocol-os-right {
            .copy-wrapper {
                .leftcol {
                    flex-basis: 50%;
                    margin-right: 4.7%;
                }
                .rightcol {
                    flex-basis: 45.3%;
                }
            }
        }
	}
    @include bpr-media-breakpoint(down, lg) {
        &.break-early {
            .copy-wrapper {
                .leftcol {
                    margin-bottom: 2em;
                    &.no-margin {
                        margin-bottom: 0;
                    }
                }
                h3.key-heading {
                    display: inline-block;
                }
            }
            &.pb-copy-panel__twocol-os-right {
                .copy-wrapper {
                    display: block;
                    .leftcol, .rightcol {
                        width: 100%;
                    }
                    .leftcol {
                        margin-bottom: 4em;
                    }
                }
            }
            &.pb-copy-panel__twocol-fifty {
                .copy-wrapper {
                    display: block;
                    .leftcol, .rightcol {
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
        .expanding-panels {
            text-align: left;
        }
	}
    @include bpr-media-breakpoint(down, md) {
        .copy-wrapper {
            .leftcol {
                margin-bottom: 2em;
                &.no-margin {
                    margin-bottom: 0;
                }
            }
        }
        &__twocol-os-right {
            .copy-wrapper {
                display: block;
                .leftcol, .rightcol {
                    width: 100%;
                }
                .leftcol {
                    margin-bottom: 4em;
                    text-align: center;
                }
            }
        }
        &__twocol-fifty {
            .copy-wrapper {
                display: block;
                .leftcol, .rightcol {
                    width: 100%;
                    text-align: center;
                }
            }
        }
	}
}